import React, { useContext } from "react";
import "./Verification.scss";
import DataContext from "../context/DataContext";
import preloaderGif from "../assets/img/preloader-1.gif";

const Base = ({ children}) => {
  
  const { isLoading, isPreLoading, isLoadingColor } = useContext(DataContext);

  return (
    <div>
      <section className="main-component">{children}</section>

      <div className="bottom-wave">
        <div className="wave"></div>
        <div className="wave"></div>
      </div>

      {isLoading && (       
        <div className={`verification-loader`}>
          <div class={`circle-loader ${isLoadingColor}`}>
            <div class="status draw"></div>
          </div>
        </div>
      )}

      {isPreLoading && (
        <div className="preloader-section">
          <img src={preloaderGif} alt="PreLoading..." />
        </div>
      )}
    </div>
  );
};

export default Base;
