import axios from "axios";
import Cookies from "universal-cookie";

const { REACT_APP_BASE_URL, REACT_APP_CLIENT_ID } = process.env;

const cookies = new Cookies();

const createAxiosInstance = (baseURL) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      client_id: REACT_APP_CLIENT_ID,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "Content-Type": "application/json",
    },
  });
};

const verificationAPI = createAxiosInstance(REACT_APP_BASE_URL);

//Interceptors
const applyAccessTokenInterceptor = (apiInstance) => {
  apiInstance.interceptors.request.use(
    (req) => {
      const token = cookies.get("access_token");
      if (token) {
        req.headers["access_token"] = token; // Set Access Token
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  apiInstance.interceptors.response.use(
    (req) => {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

applyAccessTokenInterceptor(verificationAPI);

//Get Verification
export const getVerificationInfo = (verificationID) =>
  new Promise((resolve, reject) => {
    verificationAPI
      .get(`/v3/int/human-token/user?verification_id=${verificationID}`)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        resolve({ status: "error", msg: error.response.status });
      });
  });

export const sendOTP = (requestData) =>
  new Promise((resolve, reject) => {
    verificationAPI
      .post(`/v1/user/mobilelogin`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        resolve({ status: "error", msg: error.response.status });
      });
  });

export const verifyOTP = (requestData) =>
  new Promise((resolve, reject) => {

    verificationAPI
      .post(`/v1/user/mobile/otp`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        resolve({ status: "error", msg: error.response.status });
      });
  });

export const raiseDeleteRequest = (accessToken, requestData) =>
  new Promise((resolve, reject) => {

    verificationAPI
      .post(
        `/v1/user/delete/request`,
        requestData,
        {
          headers: {
            ...verificationAPI.defaults.headers,
            access_token: accessToken,
          },
        }
      )
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        resolve({ status: "error", msg: error.response.status });
      });
  });



export { verificationAPI };
