import React, {  } from 'react'
import "./HomePage.scss";
// import DataContext from '../context/DataContext';
// import { faCircleCheck, faEnvelope, faMobileScreenButton, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Card, Col, Row } from 'react-bootstrap';
import VerifyOTP from './VerifyOTP';

const HomePage = () => {

  // const { handleVerify, verificationObj } = useContext(DataContext);

  return (
    <div>
      <div className="verification-home">
       
        <h4 className="app-name">Deep Holistics</h4>
        <h4 className="heading">Account Management</h4>
        <div className="app-logo">
          <img
            src={require("../assets/img/dh-logo-dark.png")}
            alt="verify-email-icon"
          />
        </div>

        <div className="verify-here">
          <span className="title">Deactivation or Deletion</span>
          <p className="desc"></p>
        </div>
        <div className="verification-info">
          <VerifyOTP type={'mobile'} />
        </div>
      </div>
    </div>
  );
}

export default HomePage