import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getVerificationInfo } from "../utils/verificationAPI";
const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [isPreLoading, setIsPreLoading] = useState(false);

  const [isLoadingColor, setIsLoadingColor] = useState();

  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  const [verificationID, setVerificationID] = useState("");
  const [verificationLevel, setVerificationLevel] = useState("home");
  const [verificationObj, setVerificationObj] = useState([]);

  const handleVerify = (level) =>{
    setVerificationLevel(level)
  }

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const VidParam = urlParams.get('verification_id');
    setVerificationID(VidParam);
  },[])

  const getVerificationStatus = async () => {
    setIsPreLoading(true);
    try {
      const getVerificationInfoResponse = await getVerificationInfo(verificationID);
      setVerificationObj(getVerificationInfoResponse?.data?.user_detail || []) 
    } catch (error) {
      console.error('Error fetching data:', error.response ? error.response.data : error.message);      
      
    } finally {
      setIsPreLoading(false);
    }
  };

  useEffect(() => {
    if (verificationID) {
      getVerificationStatus()
    }
  }, [verificationID]);


  const ShowNotification = (type, message) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 800,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <DataContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isLoadingColor, setIsLoadingColor,
        isPreLoading, setIsPreLoading,
        isLoadingComplete,
        setIsLoadingComplete,
        verificationLevel, setVerificationLevel,
        handleVerify,
        verificationObj, setVerificationObj,
        ShowNotification,
        verificationID,
        getVerificationStatus
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
