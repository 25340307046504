import React, { useContext, useEffect, useState } from "react";
import "./Verification.scss";
import OTPInput from "react-otp-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faHand, faPaperPlane, faPenToSquare, faTruckFast } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'react-bootstrap';
import DataContext from "../context/DataContext";
import { raiseDeleteRequest, sendOTP, verifyOTP } from "../utils/verificationAPI";
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputAdornment, Radio, RadioGroup, TextField } from "@mui/material";
import Swal from "sweetalert2";

const VerifyOTP = ({ type }) => {
  const {
    verificationObj,
    setIsLoading,
    setIsLoadingColor,
    setIsLoadingComplete,
    ShowNotification,
    setVerificationLevel,
    // verificationID,
    // getVerificationStatus
  } = useContext(DataContext);

  const [otp, setOtp] = useState("");

  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isShowOtpView, setIsShowOtpView] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [isTimeOut, setIsTimeOut] = useState(true);

  /*  */

  const [mobileNumber, setMobileNumber] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  /*  */

  useEffect(() => {
    if (!timeLeft) {
      setIsTimeOut(true);
      return;
    }
    const timer = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formattedTime = `${Math.floor(timeLeft / 60)}:${(timeLeft % 60 < 10 ? '0' : '')}${timeLeft % 60}`;

  /*  */
  const [selectedRadio, setSelectedRadio] = useState("Delete my account and all related data");
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);

  const handleMobileNumberChange = (e) => {
    const input = e.target.value;
    const sanitizedInput = input.replace(/\D/g, '');
    if (sanitizedInput.length <= 10) {
      setMobileNumber(sanitizedInput);
    }
  };

  const handleRadioChange = (event) => {
    const newValue = event.target.value;
    setSelectedRadio(newValue);
  }

  const handleCheckboxChange = (event) => {
    console.log(event.target.checked)
    setIsAcceptTerms(event.target.checked);
  };


  /*  */
  const handleSendOTP = async () => {

    if (mobileNumber?.length === 10) {
      setIsSending(true);

      let requestData = {
        mobile: mobileNumber,
        country_code: "91",
        mobile_type: "android",
      };

      try {
        const sendOTPResponse = await sendOTP(requestData);

        if (sendOTPResponse?.status === "success") {
          setIsSending(false);
          setIsSent(true);

          setTimeout(() => {
            setIsShowOtpView(true);
            setIsTimeOut(false);
            setTimeLeft(10);
          }, 900);
        } else if (sendOTPResponse?.status === "error") {
          ShowNotification("error", "Unable Send OTP");
          setIsSending(false);
        }
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );

        ShowNotification("error", "Cannot send OTP. Please contact admin");
      } finally {
      }
    }else{
      ShowNotification('info', "Please enter valid phone Number")
    }
    
  };

  const handleVerifyOTP = async () => {
    setIsLoading(true);
    setIsLoadingColor("")
    let requestData = {
      mobile: mobileNumber,
      country_code: "91",
      otp: otp,
      app_version: "DEBUG_1.2.23",
      mobile_type: "android",
      mobile_details: {
        device: "Test Device",
        model_no: "8T",
        manufacturer: "OnePlus",
        os_build_version: "10.0",
        mobile_unique_id: "123123123",
      },
    };

    try {
      const verifyOTPResponse = await verifyOTP(requestData);

      if (verifyOTPResponse?.status === "success") {
        console.log("verifyOTPResponse",verifyOTPResponse)
        setAccessToken(verifyOTPResponse?.data?.access_token);

        setIsLoadingColor("success")
        setIsLoadingComplete(true);
        
        setTimeout(() => {
          setIsLoading(false);
          setIsLoadingComplete(false);
          setVerificationLevel("home");
          setIsVerified(true)
        }, 1000);

      }else if (verifyOTPResponse?.status === "error") {

        setIsLoadingColor("failed")
        setIsLoadingComplete(true);
        
        ShowNotification("error", "Unable to verify OTP");

        setTimeout(() => {
          setIsLoading(false);
          setIsLoadingComplete(false);
          
          setOtp("")
        }, 1000);

      }
    } catch (error) {
      console.error("Error fetching data:", error.response ? error.response.data : error.message);
    } finally {
      
    }
  };

  const handleResendOTP = async () => {

    let requestData = {
      mobile: mobileNumber,
      country_code: "91",
      mobile_type: "android",
    };

    try {
      const sendOTPResponse = await sendOTP(requestData);

      if (sendOTPResponse?.status === "success") {
        setIsTimeOut(false);
        setTimeLeft(20);
      }
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
    } finally {
    }
  };

  const handleRaiseRequest = async () => {

    let requestData = {
      is_terms: isAcceptTerms,
      radio_input: selectedRadio,
    }; 

    try {
      const deleteRequestResponse = await raiseDeleteRequest(accessToken, requestData);
      
      if (deleteRequestResponse?.status === "success") {
        Swal.fire(
          "<div><h5>Success</h5><h6>Successfully raised a delete request. Your account/data will be deleted in 3 to 5 days.</h6></div>",
          "",
          "success"
        );
      }else{
        Swal.fire("<h5>Already raised a request</h5>", "", "info");
      }
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
    } finally {
    }
  };

  return (
    <section>
      <div className="verification-section">
        {isVerified ? (
          <>
            <section className="confirmation-section">
              <FormControl>
                <RadioGroup
                  value={selectedRadio}
                  onChange={handleRadioChange}
                  defaultValue="Delete my account and all related data"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="Delete only the data stored in my account, and not the account itself"
                    control={<Radio />}
                    label="Delete only the data stored in my account, and not the account itself"
                  />
                  <FormControlLabel
                    value="Delete my account and all related data"
                    control={<Radio />}
                    label="Delete my account and all related data"
                  />
                </RadioGroup>
              </FormControl>
              <FormGroup className="confirmation-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAcceptTerms}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="I hereby give consent to delete the request information from Deepholistics systems"
                />
              </FormGroup>
            </section>

            <Button
              variant="outline-success"
              size="md"
              className={`request-button`}
              onClick={handleRaiseRequest}
              disabled={!isAcceptTerms}
            >
              <FontAwesomeIcon icon={faHand} /> Raise a Delete Request
            </Button>
            <div className="instructions">
              <p>
                Click the above button to raise a request to delete your account
                / related data from our systems. Once the request is raised,
                depending on your consent, your account and all its related
                information will be deleted and removed from our systems within
                3-5 days
              </p>
            </div>
          </>
        ) : (
          <div>
            {!isShowOtpView ? (
              <section className="content-section">
                <p>
                  Please verify your Mobile number for raising request to delete
                  your account/data.
                </p>

                <div className="input-section">
                  <TextField
                    id="Mobile Number"
                    label="Mobile Number"
                    variant="outlined"
                    type="tel"
                    // size="small"
                    fullWidth
                    value={mobileNumber}
                    onChange={handleMobileNumberChange}
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        handleSendOTP();
                      }
                    }}
                  />
                </div>

                <div className="otp-button" onClick={handleSendOTP}>
                  <div
                    className={`send_btn ${
                      isSending || isSent ? "sending" : ""
                    } ${isSent ? "sent" : ""}`}
                  >
                    <FontAwesomeIcon icon={faTruckFast} />
                  </div>
                  <div
                    className={`message ${
                      isSending || isSent ? "sending" : ""
                    } ${isSent ? "sent" : ""}`}
                  >
                    {isSending ? "Sending" : isSent ? "OTP Sent" : "Send OTP"}
                  </div>
                </div>
                <div className="instructions">
                  <h4>Steps to delete your account / data:</h4>
                  <p>
                    Enter your registered phone number to verify it and raise a
                    request to delete your account or any data related to your
                    account. Once the request is raised, depending on your
                    consent, your account and all its related information will
                    be deleted and removed from our systems within 3-5 days
                  </p>
                </div>
              </section>
            ) : (
              <div
                className={`otp-section fade-in ${
                  isShowOtpView ? "visible" : ""
                }`}
              >
                <p>
                  An OTP has been sent to{" "}
                  <span>
                    {type === "email"
                      ? `${verificationObj?.[type]}`
                      : `+91 ${mobileNumber}`}{" "}
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      onClick={() => {
                        
                        setIsSending(false);
                        setIsSent(false);
                        setIsShowOtpView(true);
                        setIsTimeOut(true);

                        setIsShowOtpView(false);
                      }}
                    />
                  </span>
                </p>
                <p>
                  Enter the 4-digit verification code that was sent to your{" "}
                  {type === "email" ? "E-Mail" : "Mobile Number"}.
                </p>
                <div className="otp-input">
                  <OTPInput
                    className="input"
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={""}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <Button
                  variant="outline-success"
                  size="md"
                  className={`submit-otp`}
                  disabled={otp.length !== 4}
                  onClick={handleVerifyOTP}
                >
                  <FontAwesomeIcon icon={faCircleCheck} /> Verify OTP
                </Button>
                <div className="resend-otp">
                  {!isTimeOut ? (
                    <p>
                      wait <span>{formattedTime}</span> Sec. to send new code
                    </p>
                  ) : (
                    <p>
                      Didn't receive code?{" "}
                      <span onClick={handleResendOTP}>
                        Resend <FontAwesomeIcon icon={faPaperPlane} />
                      </span>
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};


export default VerifyOTP