import React, { useContext } from 'react'
import HomePage from './HomePage';
// import EmailVerify from './EMailVerify';
// import MobileVerify from './MobileVerify';
import DataContext from '../context/DataContext';
import VerifyOTP from './VerifyOTP';

const Verification = () => {

  const { verificationLevel } =
    useContext(DataContext);

  return (
    <div>
      <HomePage />
      
    </div>
  );
}

export default Verification