import "./App.css";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
// import HomePage from "./components/HomePage";
// import { Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Base from "./components/Base";
import Verification from "./components/Verification";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            element={
              <Base>
                <Outlet />
              </Base>
            }
          >
            <Route path="/" element={<Verification />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
